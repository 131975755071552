import { Avatar, Button, Flex, Layout, Typography } from 'antd';
import {
  UserOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { getUser } from '../../services/userService';

const headerStyle = {
  paddingLeft: 0,
  width: '100%',
  background: '#76738C',
  height: '60px',
  position: 'sticky',
  top: 0,
  zIndex: 2,
};

export default function AppHeader({ collapsed, changeCollapsed }) {
  const [userData, setUserData] = useState({
    name: sessionStorage.getItem('name'),
    email: sessionStorage.getItem('email'),
    photo: sessionStorage.getItem('photo'),
  });

  useEffect(() => {
    if (!sessionStorage.getItem('name'))
      getUser().then((response) => {
        if (response?.firstName) {
          sessionStorage.setItem(
            'name',
            response.lastName + ' ' + response.firstName
          );
          sessionStorage.setItem('email', response.email);
          sessionStorage.setItem('photo', response.photo);
        }

        setUserData({
          name: sessionStorage.getItem('name'),
          email: sessionStorage.getItem('email'),
          photo: sessionStorage.getItem('photo'),
        });
      });
  }, []);

  return (
    <Layout.Header style={headerStyle}>
      <Button
        type='text'
        icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        onClick={changeCollapsed}
        style={{
          fontSize: '16px',
          width: 60,
          height: 60,
        }}
      />
      <Flex
        gap={'1rem'}
        style={{
          height: '60px',
          float: 'right',
          alignItems: 'center',
        }}
      >
        <Avatar
          size={44}
          icon={<UserOutlined />}
          src={userData?.photo}
        ></Avatar>
        <div>
          <Typography.Text
            style={{ display: 'block', margin: 0, fontWeight: 'bold' }}
          >
            {userData?.name}
          </Typography.Text>
          <Typography.Text style={{ display: 'block', margin: 0 }}>
            {userData?.email}
          </Typography.Text>
        </div>
      </Flex>
    </Layout.Header>
  );
}
